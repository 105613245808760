.button-container {
  margin-left: 5px; /* Padding externo izquierdo */
  margin-right: 5px; /* Padding externo derecho */
}

.sign-in-button {
  background-color: #16744f; /* Color de fondo normal */
  color: #fff; /* Color de texto siempre blanco */
  border: none;
  border-radius: 20px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra muy tenue */
  transition: background-color 0.3s, color 0.3s; /* Transición suave para el color de fondo y el color del texto */
}

.sign-in-button:hover {
  background-color: #de1f25; /* Cambia el color de fondo al pasar el cursor */
  color: #fff; /* El color del texto sigue siendo blanco */
}