.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    animation: slideDown 0.3s ease; 
    width: 70%;
    max-width: 500px;
    overflow-y: auto;
    overflow-x: auto;
}

.modal.in .modal-dialog 
{
    -webkit-transform: translate(0, calc(50vh - 50%));
    -ms-transform: translate(0, 50vh) translate(0, -50%);
    -o-transform: translate(0, calc(50vh - 50%));
    transform: translate(0, 50vh) translate(0, -50%);
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
}

.accept-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}
